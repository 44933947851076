// noinspection CssUnusedSymbol

import styled from 'styled-components'
import ImageOverdue from '../../../images/icons/invoice-overdue.svg'

export const InvoiceListContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  .data-table-row {
    background: #000000;
    border-radius: 0;
    box-shadow: none;

    .data-table-cell {
      color: #ffffff;
    }

    .no-border {
      margin-bottom: 0;
    }
  }

  .buttons {
    padding: 0 0 2rem;

    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #828282;

    span {
      display: inline-flex;
      align-items: center;
      color: #828282;
      cursor: pointer;
      text-decoration: none;
    }

    img,
    svg {
      margin-right: 0.8rem;
    }
  }

  .invoice-first-cell {
    width: 5rem;
    text-align: center;

    .icon {
      display: none;
    }
  }

  .invoice-viewed-cell {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    color: #828282;
    padding-bottom: 3rem;

    svg {
      margin-right: 0.8rem;
    }
  }

  .invoice-amount-cell {
    color: #ffffff;
    font-weight: 600;
  }

  .invoice-status-cell {
    .invoice-status-wrap,
    .select-wrapper,
    .paid {
      width: 100%;
    }
  }

  .invoice-preview-cell {
    margin: 0.5rem auto 0;
    padding-right: 2rem;

    .invoice-preview-text {
      margin-left: 1rem;
    }
    .invoice-preview-button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
      cursor: pointer;

      &.is-disabled {
        cursor: not-allowed !important;
      }
    }
  }

  .invoice-edit-cell {
    .button {
      font-size: 1.6rem;
      font-weight: normal;
      width: 100%;
      height: 4rem;
    }
  }

  .invoice-send-cell {
    width: 4rem;
    text-align: center;
  }

  .invoice-hidden {
    display: none;
  }

  .invoices-pagination {
    padding: 0;
  }

  @media (min-width: 600px) {
    .invoice-viewed-cell {
      padding-bottom: 1rem;
    }

    .invoice-status-cell {
      //padding-top: 1rem;

      .invoice-status-wrap,
      .select-wrapper,
      .paid {
        width: 24rem;
      }

      .invoice-status-wrap,
      .paid {
        margin: 0 auto;
      }
    }

    .invoice-edit-cell {
      .button {
        max-width: 24rem;
        margin: 0 auto;
      }
    }
  }

  @media (min-width: 1200px) {
    flex-direction: column;

    .buttons {
      padding: 2.5rem 0 0;
    }

    .col-spacing {
      padding-left: 2.2rem;
    }

    .data-table-row {
      background: transparent;
      box-shadow: none;
      border-radius: 0;
      min-height: 6rem;

      &.editable {
        cursor: pointer;
      }

      :hover {
        .invoice-first-cell .icon {
          display: inline-block;
        }
      }
    }

    .invoice-viewed-cell {
      padding-bottom: 0;
      text-align: center;
      //width: 4rem;
      margin: 0 0 0 -10px;

      svg {
        margin: 0 auto;
      }

      .invoice-viewed-text {
        display: none;
      }
    }

    .invoice-status-cell {
      width: 18rem;

      .invoice-status-wrap,
      .select-wrapper,
      .paid {
        width: 16rem;
      }
    }

    .invoice-preview-cell {
      width: 4.5rem;

      .invoice-preview-text {
        display: none;
      }

      .invoice-preview-button {
        justify-content: flex-end;
        width: auto;
      }

      :hover {
        svg * {
          stroke: #ffffff;
        }
      }
    }

    .invoice-edit-cell {
      display: none !important;
    }

    .invoice-hidden {
      display: table-cell;
    }

    .invoices-pagination {
      padding: 3rem 0 0;
    }
  }

  .invoices-container {
    .overdue {
      color: #ee2b2b;
      padding-left: 2.2rem;
      //prettier-ignore
      background: url("${ImageOverdue}") 0 0 no-repeat;
    }

    .select-wrapper {
      display: inline-block;
    }
  }
`

export const StatusStatic = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;

  height: 4rem;
  width: 16rem;
  border-radius: 9.6rem;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;

  &.paid {
    background: #00b451;
    color: black;
  }

  &.canceled,
  &.enotas {
    background: #828282;
    color: black;
  }
`
