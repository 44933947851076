// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const MessageWrapper = styled.div`
  grid-row: 2 / 3;
  grid-column: left-column / right-column;
  width: 100%;
  padding: 10rem 0 0;
  margin: 0;
`

export const MessageContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 30rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: calc(100% - 4rem);
    max-width: 60rem;
    margin: 0 2rem;
  }
`

export const MessageContent = styled.div`
  margin: 5rem 0;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 2.4rem;

  .title {
    color: black;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3rem;
    margin-top: 2rem;
  }

  .subtitle {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.4rem;
    color: #f7f7f7;
    margin: 0.5rem 0 2rem;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
    }

    .button {
      width: 32rem;
      text-transform: uppercase;
    }
  }
`
