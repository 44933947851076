import { useContext } from 'react'

import { ButtonWrapper } from './NewInvoiceButton.styled'
import { GlobalContext } from 'mmfintech-commons'
import { NewInvoiceModal } from '../NewInvoiceModal'

import NewInvoiceIcon from '../../../images/icons/invoice-new.svg?react'

export const NewInvoiceButton = () => {
  const { modalShow } = useContext(GlobalContext)

  const handleNewInvoiceClick = () => {
    modalShow({
      options: { maxWidth: 940, size: 'medium' },
      content: <NewInvoiceModal />
    })
  }

  return (
    <ButtonWrapper data-test='new-invoice-button' onClick={handleNewInvoiceClick}>
      <NewInvoiceIcon />
    </ButtonWrapper>
  )
}
