import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { useAppDispatch } from '../../../hooks'
import { loggedIn, paths, useLoginReqMutation } from 'mmfintech-backend-api'
import { OtpContext, tr, useFormValues } from 'mmfintech-commons'

import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { LoginContent, OuterLink } from './Login.styled'
import { CustomInput, GlobalTitle, GlobalWrapper } from '../../../components'

export const Login = () => {
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [login, { error: loginError, isLoading: loginFetching, reset: resetLogin }] = useLoginReqMutation()

  const dispatch = useAppDispatch()

  const formValues = useFormValues({
    email: { required: true, validation: 'email' },
    password: { required: true }
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setOtpOnSuccess(() => (response: any) => {
      dispatch(loggedIn(response))
    })
    if (formValues.areValid()) {
      try {
        await login(formValues.prepare()).unwrap()
      } catch (error) {}
    }
  }

  const handleResetError = () => {
    if (loginError) {
      resetLogin()
    }
  }

  return (
    <GlobalWrapper type={1}>
      <GlobalTitle>{tr('FRONTEND.LOGIN.TITLE', 'Login')}</GlobalTitle>
      <LoginContent noValidate onSubmit={handleSubmit}>
        <CustomInput
          icon='email'
          type='email'
          data-test='email'
          label={tr('FRONTEND.LOGIN.EMAIL', 'Email')}
          {...formValues.registerInput('email', handleResetError)}
          disabled={loginFetching}
          hideErrorLine
          autoFocus
        />

        <CustomInput
          icon='lock'
          type='password'
          data-test='password'
          label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
          {...formValues.registerInput('password', handleResetError)}
          disabled={loginFetching}
          autoComplete='off'
          hideErrorLine
        />

        <ErrorDisplay error={loginError} />

        <div className='buttons'>
          <Button
            type='submit'
            color='primary'
            data-test='button-submit'
            text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
            disabled={!formValues.getValue('email')?.length || !formValues.getValue('password')?.length}
            loading={loginFetching}
            iconPosition='right'
            icon='right-arrow'
          />
        </div>

        <div className='inner-link'>
          {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}{' '}
          <Link to={paths.signUp()} data-test='sign-up'>
            <span>{tr('FRONTEND.LOGIN.SIGN_UP_FREE', 'Sign up for FREE')}</span>
          </Link>
        </div>
      </LoginContent>

      <OuterLink>
        <Link to={paths.forgotPassword()} data-test='forgot-password'>
          {tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}
        </Link>
      </OuterLink>
    </GlobalWrapper>
  )
}
