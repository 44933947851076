import styled from 'styled-components'

export const ExpenseRequestModalWrapper = styled.div`
  width: 100vw;
  max-width: 94rem;
  position: relative;
  background: #1a1a1a;
  color: white;
  padding: 9rem 2rem 4rem;

  @media (min-width: 560px) {
    padding: 4rem 5rem 5rem;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 100%;
    margin: 0;
  }

  p {
    color: #afafaf;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
    width: 100%;
    margin-bottom: 3rem;

    @media (min-width: 550px) {
      width: 60%;
    }
  }
`
