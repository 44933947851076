import styled from 'styled-components'

export const CustomerEditWrapper = styled.div`
  width: 98vw;
  max-width: 94rem;
  position: relative;

  padding: 7rem 2rem 4rem;

  @media (min-width: 800px) {
    padding: 5rem 7rem;
  }

  background: #1a1a1a;
`

export const CustomerEditTitle = styled.div`
  color: #ffffff;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 3rem;

  @media (min-width: 800px) {
    font-size: 2.4rem;
    margin-bottom: 6rem;
  }
`

export const StyledForm = styled.form`
  margin: 0;
`

export const StyledFormButtons = styled.div`
  margin-top: 6rem;
  text-align: right;

  .button {
    max-width: 20rem;
  }
`

export const CustomerEditInner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4.5rem;
    row-gap: 0;
  }
`
