import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAuth } from 'mmfintech-backend-api'

import { LoginStatusEnum } from 'mmfintech-commons-types'

interface PrivateRouteProps extends RouteProps {
  redirect: string
  invalidSessionRedirect: string
}

export function PrivateRoute(props: PrivateRouteProps) {
  const { component: Component, redirect, invalidSessionRedirect, ...rest } = props

  const userStatus = useAuth()

  if (redirect) {
    return <Route {...rest} render={() => <Redirect to={redirect} />} />
  }

  return (
    <Route
      {...rest}
      render={props =>
        userStatus === LoginStatusEnum.LOGGED_IN ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: invalidSessionRedirect,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
