import styled from 'styled-components'
import { Line } from 'react-chartjs-2'

import { isValidArray } from 'mmfintech-commons'
import { selectMerchantCurrency, useAppSelector, useGraphQry } from 'mmfintech-backend-api'

export const Chart = () => {
  const merchantCurrency = useAppSelector(selectMerchantCurrency)
  const { chartData, chartLabels, isEmptyBalance } = useGraphQry()

  const prepareData = () => ({
    labels: chartLabels,
    datasets: [
      {
        data: chartData,
        fill: true,
        backgroundColor: context => {
          const chart = context.chart
          const { ctx, chartArea } = chart
          if (!chartArea) {
            return
          }
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0)')
          gradient.addColorStop(1, 'rgba(255, 255, 255, .15)')
          return gradient
        },
        borderColor: '#4f4f4f',
        borderWidth: 2,
        pointBackgroundColor: '#ffffff',
        pointBorderWidth: 0,
        pointHitRadius: 6,
        pointRadius: 2.5,
        hoverBackgroundColor: 'rgba(255, 255, 255, 0.2)',
        hoverBorderWidth: 6
      }
    ]
  })

  // noinspection JSUnusedGlobalSymbols
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#808080'
        },
        grid: {
          borderColor: '#4f4f4f',
          display: false
        }
      },
      y: {
        max: isEmptyBalance() ? 5 : null,
        min: isEmptyBalance() ? 0 : null,
        ticks: {
          color: '#808080',
          callback: (value, index) => (index % 2 === 0 ? value.toFixed(2) : null)
        },
        grid: {
          borderColor: '#4f4f4f',
          borderDash: [1, 5],
          color: '#4f4f4f'
        }
      }
    },
    plugins: {
      tooltip: {
        displayColors: false,
        caretPadding: 10,
        backgroundColor: '#4f4f4f',
        bodyColor: '#ffffff',
        padding: 10,
        // bodyAlign: 'left',
        // bodyFont: {
        //   family: 'Helvetica Now Display',
        //   weight: 400,
        //   size: 14,
        //   lineHeight: 0.5
        // },
        titleColor: '#afafaf',
        // titleFont: {
        //   weight: 400,
        //   size: 10,
        //   family: 'Helvetica Now Display'
        // },
        callbacks: {
          title: () => merchantCurrency,
          label: (context: any) => {
            if (context.raw === 0) return context.raw + ''

            if (context.raw % 1 === 0) {
              return context.raw + '.00'
            } else {
              if ((context.raw + '').split('.')[1].length === 1) {
                return context.raw + '0'
              } else {
                return context.raw
              }
            }
          }
        }
      },
      legend: {
        display: false
      }
    }
  }

  if (isValidArray(chartData)) {
    // @ts-ignore
    return (
      <ChartWrapper>
        <Line data={prepareData} options={options} data-test='balance-chart' />
      </ChartWrapper>
    )
  }

  return null
}

const ChartWrapper = styled.div`
  width: 100%;
  height: 32.5rem;
  position: relative;
  overflow-x: hidden;
`
