import styled from 'styled-components'

export const TransactionResultWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(44px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 7px;

  width: 100%;
  max-width: 45rem;
  min-height: 25rem;

  color: white;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;

  .icon {
    margin-bottom: 1.5rem;
  }
`

export const TransactionResultButtons = styled.div`
  margin-top: 4rem;

  .button {
    width: 100vw;
    max-width: 20rem;
  }
`

export const TransactionResultMessage = styled.div`
  text-align: center;
  padding: 0 1rem;
`
