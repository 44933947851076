import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import { selectCurrentLanguage, useAppSelector } from 'mmfintech-backend-api'

import { isDefined, isValidFunction, tr } from 'mmfintech-commons'

import Select from 'react-select'
import DatePicker from 'react-datepicker'
import PhoneInput from 'react-phone-input-2'
import ReactTooltip from 'react-tooltip'
import {
  InputText,
  InputTextArea,
  CustomInputWrapper,
  StyledDatePickerWrapper,
  StyledPhoneInputWrapper,
  CustomInputError
} from './CustomInput.styled'

import ShowIcon from '../../images/icons/input-eye-black.svg?react'
import HideIcon from '../../images/icons/input-password-hide.svg?react'

import GlobeIcon from '../../images/icons/input-select-country.svg?react'
import EmailIcon from '../../images/icons/input-username.svg?react'
import LockIcon from '../../images/icons/input-password.svg?react'
import UserIcon from '../../images/icons/input-select-account-type.svg?react'
import LanguageIcon from '../../images/icons/input-language.svg?react'
import ReferenceIcon from '../../images/icons/input-reference.svg?react'

import { SelectOption } from 'mmfintech-commons-types'
import { CustomInputProps } from './CustomInput.types'

const predefinedIcons = {
  email: <EmailIcon className='fill' />,
  globe: <GlobeIcon />,
  lock: <LockIcon className='fill' />,
  user: <UserIcon className='fill' />,
  language: <LanguageIcon />,
  reference: <ReferenceIcon />
}

export const CustomInput = (props: CustomInputProps) => {
  const {
    id,
    icon,
    name,
    type,
    error,
    label,
    value,
    options,
    tooltip,
    onChange,
    required = false,
    hideLabel,
    hideBorder,
    placeholder,
    hideRequired,
    hideErrorLine,
    hideShowPassword,
    excludedCountries,

    className,
    parentClassName,
    ...rest
  } = props

  const selectedLanguage = useAppSelector(selectCurrentLanguage)

  const [focused, setFocused] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<SelectOption>(null)
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

  const getRequired = (value?: string): string => (required && !hideRequired ? '*' : '') + value

  const prepareIcon = () =>
    typeof icon === 'string' && predefinedIcons.hasOwnProperty(icon) ? predefinedIcons[icon] : icon

  const getPlaceholder = () => {
    if (!focused) {
      if (placeholder?.length) return getRequired(placeholder)
      if (label?.length) return getRequired(label)
    }
    return null
  }

  const getShowPasswordIcon = () => {
    if (passwordVisible) {
      return (
        <HideIcon onClick={() => setPasswordVisible(!passwordVisible)} title={tr('FRONTEND.BUTTONS.HIDE', 'hide')} />
      )
    }
    return <ShowIcon onClick={() => setPasswordVisible(!passwordVisible)} title={tr('FRONTEND.BUTTONS.SHOW', 'Show')} />
  }

  const handleBlur = () => setFocused(false)

  const handleEnter = () => setFocused(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    isValidFunction(onChange) && onChange(e.target.name, e.target.value)
  }

  const handleSelectChange = (selectedOption: SelectOption): void => {
    setSelectedOption(selectedOption)
    isValidFunction(onChange) && onChange(name, selectedOption.value, required)
  }

  const isErrorVisible = () => {
    return !hideErrorLine || error?.length > 0
  }

  const isLabelVisible = () => typeof label === 'string' && label.length > 0

  useEffect(() => {
    const list = document.getElementsByClassName('flag-dropdown')
    for (const item of list) {
      item.removeAttribute('tabindex')
    }
  }, [])

  useEffect(() => {
    const option = options?.find(item => item.value === value || item.value === value?.toString())
    setSelectedOption(option)
    // eslint-disable-next-line
  }, [value, options, selectedOption])

  const rules = {}
  if (required !== undefined && required) {
    rules['required'] = true
  }

  const defaultStyles = {
    container: (provided: any) => ({
      ...provided,
      color: '#000000',
      fontFamily: 'inherit',
      fontSize: '1.8rem',
      fontWeight: '400',
      lineHeight: '140%',
      width: '100%'
    }),
    control: (provided: any) => ({
      ...provided,
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      height: '5rem'
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: 0
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#afafaf',
      fontFamily: "'Helvetica Now Display', sans-serif",
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '140%'
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      paddingRight: 0
    }),
    menu: (provided: any) => ({
      ...provided,
      background: '#ffffff',
      border: 'none',
      borderRadius: '0.5rem',
      padding: '5px 1rem',
      ':hover': {
        border: 'none !important'
      }
    }),
    option: (provided: any, props: any) => ({
      ...provided,
      cursor: 'pointer',
      color: props?.value === 'new' ? '#ffffff' : '#000000',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '140%',
      textAlign: props?.value === 'new' ? 'center' : 'left',
      borderRadius: props?.value === 'new' ? '2rem' : '0',
      background: props?.value === 'new' ? '#1a1a1a' : '#ffffff',
      width: 'calc(100% - 1rem)',
      marginTop: '3px',
      ':hover': {
        color: '#ffffff',
        background: '#000000',
        borderRadius: props?.value === 'new' ? '2rem' : '1rem'
      }
    })
  }

  const prepareLabel = () => {
    if (label?.length) {
      return (
        <label htmlFor={id || name}>
          {isLabelVisible() ? (
            <span>
              {required && !hideRequired ? <span className='asterisk'>*</span> : null}
              <span>{label}</span>
            </span>
          ) : (
            ' '
          )}
        </label>
      )
    }
    return null
  }

  const prepareInput = () => {
    if (type === 'static') {
      return (
        <div className={cn('static-input-wrapper')} id={id || name} {...rest}>
          {value || ''}
        </div>
      )
    }

    if (type === 'select') {
      return (
        <Select
          id={id}
          name={name}
          placeholder={getPlaceholder()}
          className={cn('select-input', className, { error: error && error.length > 0 })}
          options={options || []}
          value={selectedOption || ''}
          onChange={handleSelectChange}
          styles={defaultStyles}
          required={required}
          components={{
            IndicatorSeparator: () => null
          }}
        />
      )
    }

    if (type === 'date') {
      return (
        <StyledDatePickerWrapper>
          <DatePicker
            locale={selectedLanguage}
            dateFormat={['dd/MM/yyyy', 'dd/MM/yy', 'dd.MM.yyyy', 'dd.MM.yy', 'yyyy-MM-dd']}
            className={cn('date-input', className)}
            selected={value ? new Date(value) : null}
            calendarStartDay={1}
            onChange={date => onChange && onChange(name, date)}
            placeholderText={label}
            {...rest}
          />
        </StyledDatePickerWrapper>
      )
    }

    if (type === 'phone') {
      return (
        <StyledPhoneInputWrapper>
          <PhoneInput
            enableSearch
            disableSearchIcon
            placeholder={getPlaceholder()}
            value={isDefined(value) ? String(value) : ''}
            containerClass={cn('phone-input', className)}
            onChange={value => isValidFunction(onChange) && onChange(name, value)}
            inputStyle={{
              border: 'none',
              paddingLeft: '5rem',
              paddingRight: '16px',
              width: '100%'
            }}
            excludeCountries={excludedCountries}
            buttonStyle={{
              background: 'transparent',
              border: 'none',
              width: '50px'
            }}
            inputProps={{ id, name, required, ...rest }}
          />
        </StyledPhoneInputWrapper>
      )
    }

    if (type === 'textarea') {
      return (
        <InputTextArea
          {...rest}
          id={id || name}
          name={name}
          className={cn('custom-textarea', className)}
          onChange={handleChange}
          placeholder={getPlaceholder()}
          value={value || ''}
          {...rules}
        />
      )
    }

    return (
      <InputText
        type={(type === 'password' && passwordVisible) || type === 'int' || type === 'number' ? 'text' : type}
        id={id || name}
        name={name}
        value={value || ''}
        placeholder={getPlaceholder()}
        className={cn('custom-input', className, { required: required && !hideRequired })}
        onChange={handleChange}
        onFocus={handleEnter}
        onBlur={handleBlur}
        {...rules}
        {...rest}
      />
    )
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <CustomInputWrapper
      className={cn('input-wrapper', parentClassName, {
        error: error && error.length > 0
      })}
      data-test={`${type}-input-${name}`}>
      <div className={cn('input-inner-wrapper', { error: error && error.length > 0 })}>
        {icon && <span className='left-icons'>{prepareIcon()}</span>}
        <div className='input-container'>
          {focused && <label>{prepareLabel()}</label>}
          {prepareInput()}
        </div>
        {(tooltip || (type === 'password' && !hideShowPassword)) && (
          <span className='right-icons'>
            {type === 'password' && !hideShowPassword && getShowPasswordIcon()}
            {type === 'password' && !hideShowPassword && tooltip && <span className='spacer' />}
            {tooltip && <span className='info' data-for='info-tooltip' data-tip={tooltip} />}
          </span>
        )}
      </div>

      {isErrorVisible() ? (
        <CustomInputError className={cn('error-message', { 'left-icon': icon, 'phone-input-error': type === 'phone' })}>
          {error}
        </CustomInputError>
      ) : null}
    </CustomInputWrapper>
  )
}
