import { isMerchantIndividual, useGetMerchantQuery } from 'mmfintech-backend-api'

import { GraphPane } from './GraphPane'
import { AccountPane } from './AccountPane'
import { CashFlowPane } from './CashFlowPane'
import { InvoicesPane } from './InvoicesPane'
import { DashboardContainer } from '../Dashboard.styled'

export const OwnerDashboard = () => {
  const { data: merchant } = useGetMerchantQuery()

  return (
    <DashboardContainer>
      <AccountPane />
      <GraphPane />
      {isMerchantIndividual(merchant) ? <CashFlowPane /> : <InvoicesPane />}
    </DashboardContainer>
  )
}
