import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import settings from '../../../settings'
import { paths, useUploadInvoiceMutation } from 'mmfintech-backend-api'
import { checkFileUpload, GlobalContext, isValidFileArrayOrFileList, tr } from 'mmfintech-commons'

import { Button, DragAndDrop, ErrorDisplay, FileInput } from 'mmfintech-portal-commons'
import { NewInvoiceLeft, NewInvoiceRight, NewInvoiceWrapper } from './NewInvoiceModal.styled'

import PlusIcon from '../../../images/icons/deposit.svg?react'
import UploadInvoiceImage from '../../../images/speedy-upload-invoice.png'

export const NewInvoiceModal = () => {
  const { modalHide } = useContext(GlobalContext)

  const [fileError, setFileError] = useState(null)

  const history = useHistory()

  const [uploadInvoice, { error: uploadInvoiceError, isLoading: uploadInvoiceFetching }] = useUploadInvoiceMutation({
    fixedCacheKey: 'upload-invoice-mutation'
  })

  const handleAtaBankPayClick = () => {
    modalHide()
    history.push(paths.invoices.create(), { origin: 'dashboard' })
  }

  const handleFileError = (errors: any) => setFileError(checkFileUpload(errors))

  const handleFileSelected = (files: FileList | File[]) => {
    setFileError(null)
    if (isValidFileArrayOrFileList(files)) {
      uploadInvoice({ file: files[0] })
        .unwrap()
        .then(() => {
          modalHide()
          history.push(paths.invoices.upload())
        })
        .catch(() => {
          return
        })
    }
  }

  return (
    <NewInvoiceWrapper data-test='invoice-new-modal'>
      <NewInvoiceLeft className='left-side'>
        <div className='header'>
          <div className='title'>{tr('FRONTEND.INVOICE.NEW.TITLE', 'New invoice')}</div>
          <div className='subtitle'>
            {tr('FRONTEND.INVOICE.NEW.SUBTITLE', 'Create a new invoice or just drop here your invoice file.')}
          </div>
          <ErrorDisplay error={[fileError, uploadInvoiceError]} />
        </div>
        <div className='buttons'>
          <Button
            color='round-primary'
            icon={<PlusIcon className='mr-1' width={26} height={26} />}
            text='ATA Bank Pay'
            disabled={uploadInvoiceFetching}
            onClick={handleAtaBankPayClick}
            data-test='button-speedy-pay'
          />
        </div>
      </NewInvoiceLeft>

      <NewInvoiceRight className='right-side'>
        <img src={UploadInvoiceImage} alt='' />
        <DragAndDrop
          onDrop={handleFileSelected}
          onError={handleFileError}
          maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
          acceptType={settings.defaultUploadInvoiceAcceptType}>
          <FileInput
            maxNumber={1}
            maxFileSize={settings.defaultUploadInvoiceMaxFileSize}
            acceptType={settings.defaultUploadInvoiceAcceptType}
            onChange={handleFileSelected}
            onError={handleFileError}>
            {({ onFileUpload, onRemoveAll }) => (
              <>
                <div
                  className='upload-box'
                  data-test='upload-box'
                  onClick={() => {
                    onRemoveAll()
                    onFileUpload()
                  }}>
                  {tr('FRONTEND.INVOICE.NEW.DROP_HERE', 'drop invoice here')}
                  <div className='file-types'>{settings.defaultUploadInvoiceAcceptType.join(', ')}</div>
                </div>
              </>
            )}
          </FileInput>
        </DragAndDrop>
      </NewInvoiceRight>
    </NewInvoiceWrapper>
  )
}
