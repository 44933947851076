// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const MainPaneWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
`

export const MainPaneHeader = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column-reverse nowrap;
  //align-items: flex-end;
  //justify-content: space-between;
  margin-bottom: 3rem;

  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
`

export const MainPaneContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .title {
    color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.01em;
    white-space: nowrap;

    margin-right: 2rem;
  }

  .select-wrapper {
    max-width: 18rem;
  }

  @media (min-width: 1024px) {
    width: unset;
  }
`

export const MainPaneButtons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 8rem;
  grid-gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    &.triple {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .info-circle {
    position: absolute;
    top: 2rem;
    left: 2rem;

    width: 2rem;
    height: 2rem;
    padding: 1rem;
    cursor: pointer;

    img,
    svg {
      position: absolute;
      top: 0;
      left: 0;
      //width: 0.4rem;
      //height: 1rem;

      font-size: 1.6rem;
    }
  }

  .rc-tooltip-arrow-inner {
    border: 5px solid red;

    .rc-tooltip-inner {
      border-radius: 1.3rem;
    }
  }
`

export const StyledButton = styled.button`
  background: #2c2c2c;
  border-radius: 1rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.05);

  outline: none;
  border: none;
  cursor: pointer;
  &.static {
    cursor: default;
  }

  width: 100%;
  height: 100%;
  padding: 1.5rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  color: white;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.1rem;

  .icon {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin-right: 1.5rem;
  }

  .label {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .amount {
    font-size: 1.8rem;
    line-height: 2.9rem;
  }

  &.magenta-shadow,
  &.total-amount {
    .icon {
      box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.25),
        0 94px 150px rgba(135, 44, 227, 0.23),
        0 39.271px 62.6664px rgba(135, 44, 227, 0.165337),
        0 20.9961px 33.5045px rgba(135, 44, 227, 0.137105),
        0 11.7703px 18.7823px rgba(135, 44, 227, 0.115),
        0 6.2511px 9.97515px rgba(135, 44, 227, 0.0928953),
        0 2.60122px 4.15088px rgba(135, 44, 227, 0.0646635);
    }
  }
  &.green-shadow,
  &.paid-invoices {
    .icon {
      box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.25),
        0 94px 150px rgba(44, 227, 161, 0.23),
        0 39.271px 62.6664px rgba(44, 227, 161, 0.165337),
        0 20.9961px 33.5045px rgba(44, 227, 161, 0.137105),
        0 11.7703px 18.7823px rgba(44, 227, 161, 0.115),
        0 6.2511px 9.97515px rgba(44, 227, 161, 0.0928953),
        0 2.60122px 4.15088px rgba(44, 227, 161, 0.0646635);
    }
  }
  &.yellow-shadow,
  &.open-invoices {
    .icon {
      box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.25),
        0 94px 150px rgba(220, 154, 77, 0.23),
        0 39.271px 62.6664px rgba(220, 154, 77, 0.165337),
        0 20.9961px 33.5045px rgba(220, 154, 77, 0.137105),
        0 11.7703px 18.7823px rgba(220, 154, 77, 0.115),
        0 6.2511px 9.97515px rgba(220, 154, 77, 0.0928953),
        0 2.60122px 4.15088px rgba(220, 154, 77, 0.0646635);
    }
  }
  &.red-shadow,
  &.overdue-invoices {
    .icon {
      box-shadow:
        0 4px 8px rgba(0, 0, 0, 0.25),
        0 94px 150px rgba(238, 40, 40, 0.23),
        0 39.271px 62.6664px rgba(238, 40, 40, 0.165337),
        0 20.9961px 33.5045px rgba(238, 40, 40, 0.137105),
        0 11.7703px 18.7823px rgba(238, 40, 40, 0.115),
        0 6.2511px 9.97515px rgba(238, 40, 40, 0.0928953),
        0 2.60122px 4.15088px rgba(238, 40, 40, 0.0646635);
    }
  }
`
