import { Link } from 'react-router-dom'

import cn from 'classnames'
import { formatPhoneNumber, tr, useValidUntilTimer } from 'mmfintech-commons'
import { globalSettings, selectCurrentUserStatus, useAppSelector, useOtpQry } from 'mmfintech-backend-api'

import { CustomInput } from '../CustomInput'
import { EditContainer } from '../commons'
import { Button, ErrorDisplay } from 'mmfintech-portal-commons'
import { Countdown, OtpSubtitle, OtpTitle, OtpWrapper, ResendLinkWrapper, VerificationContainer } from './Otp.styled'

import { LoginStatusEnum, TwoFactorTypeEnum } from 'mmfintech-commons-types'

import Icon from '../../images/icons/input-otp.svg?react'

export const Otp = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    handleCodeChanged,
    handleCancel,
    handleSubmit,
    handleResend,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendTimer
  } = useOtpQry()

  const userStatus = useAppSelector(selectCurrentUserStatus)

  const timer = useValidUntilTimer()

  const { sentTo, twoFactorType } = challenge || {}

  const prepareTitle = () => {
    if (twoFactorType === TwoFactorTypeEnum.SMS) {
      if (userStatus === LoginStatusEnum.LOGGED_IN) {
        return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
      }

      return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
    }

    if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'Email verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'Email confirmation')
  }

  return (
    <OtpWrapper data-test='otp-verification-dialog'>
      <OtpTitle className='title'>{prepareTitle()}</OtpTitle>
      <OtpSubtitle>
        {tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', "Please, enter the code that we've sent to:")}
        <span>{twoFactorType === TwoFactorTypeEnum.SMS ? formatPhoneNumber(sentTo) : sentTo}</span>
      </OtpSubtitle>

      <VerificationContainer>
        <EditContainer className='no-padding'>
          <div className='input-container'>
            <CustomInput
              icon={<Icon />}
              type='tel'
              name='otp'
              data-test='otp'
              className={cn({
                complete: code?.length === codeSize,
                wrong: code?.length === codeSize && verifyOtpError != null
              })}
              autoComplete='off'
              onChange={(_, value: string) => handleCodeChanged(value)}
              value={code}
              maxLength={codeSize}
              autoFocus
              placeholder={tr('FRONTEND.VERIFY_OTP.ENTER_CODE', 'Enter Code')}
              disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
            />
          </div>

          <ErrorDisplay error={verifyOtpError} />

          <div className='buttons mt-3'>
            <Button
              color='primary'
              text={tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
              disabled={code.length !== codeSize || attempts >= globalSettings.otpMaxAttempts || timer.expired}
              loading={verifyOtpFetching || resendOtpFetching}
              iconPosition='right'
              icon='right-arrow'
              data-test='button-submit'
              onClick={handleSubmit}
            />
            <Button
              type='button'
              color='secondary'
              text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
              disabled={verifyOtpFetching || resendOtpFetching}
              onClick={handleCancel}
              data-test='button-cancel'
            />
          </div>
          <ResendLinkWrapper>
            {attempts >= globalSettings.otpMaxAttempts ? (
              tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
            ) : (
              <>
                {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it?")}{' '}
                {resendTimer.expired ? (
                  <Link to='#' onClick={handleResend}>
                    {tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Re-send code')}
                  </Link>
                ) : (
                  resendTimer.remainingTime
                )}
              </>
            )}
          </ResendLinkWrapper>
          {attempts >= globalSettings.otpMaxAttempts || verifyOtpFetching || resendOtpFetching ? null : (
            <Countdown>{timer.formattedTime}</Countdown>
          )}
        </EditContainer>
      </VerificationContainer>
    </OtpWrapper>
  )
}
