// noinspection DuplicatedCode

import { useHistory } from 'react-router-dom'

import { tr } from 'mmfintech-commons'
import { paths } from 'mmfintech-backend-api'

import { Button } from 'mmfintech-portal-commons'
import { MessageContainer, MessageContent, MessageWrapper } from './Error404.styled'

import Image404 from '../../../images/404.png'

export const Error404 = () => {
  const history = useHistory()

  return (
    <MessageWrapper>
      <MessageContainer>
        <MessageContent>
          <img src={Image404} alt='' />

          <div className='title'>{tr('FRONTEND.ERROR404.TITLE', 'Page not found.')}</div>
          <div className='subtitle'>{tr('FRONTEND.ERROR404.SUBTITLE', 'Please, check the link.')}</div>

          <div className='buttons'>
            <Button
              type='button'
              onClick={() => history.push(paths.root())}
              text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
              data-test='back-button'
            />
          </div>
        </MessageContent>
      </MessageContainer>
    </MessageWrapper>
  )
}
