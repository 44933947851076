import styled from 'styled-components'

export const MenuWrapper = styled.span`
  position: relative;

  &.hamburger {
    height: 6rem;
    padding: 1.6rem 0;
  }

  .title {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    width: 15rem;
    height: 2.8rem;
    background: white;
    border-radius: 1.4rem;

    color: black;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 110%;
    letter-spacing: -0.01em;

    &.opened {
      border-radius: 1.4rem 1.4rem 0 0;
    }

    .icon {
      display: flex;
      width: 14px;
      height: 14px;
      margin: 0 1.2rem 0 0.9rem;
    }
    .selected {
      width: 100%;
    }
    .arrow {
      width: 11px;
      margin: 0 1rem;
    }
  }

  .content {
    z-index: 1001;
    position: absolute;

    width: 15rem;
    padding: 1rem 0 1.6rem;

    background: white;
    border-radius: 0 0 1.4rem 1.4rem;

    color: black;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 110%;
    letter-spacing: -0.01em;

    display: none;
    &.opened {
      display: block;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      & > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        cursor: pointer;
        padding: 1rem 2.5rem 1rem 3.5rem;
        color: #000000;

        :hover {
          color: #ffffff;
          background: #000000;
        }
      }
    }
  }

  &.theme-light {
    .title {
      color: #ffffff;
      background-color: #000000;

      .icon {
        * {
          fill: #ffffff;
        }
      }
      .arrow {
        * {
          stroke: #ffffff;
        }
      }
    }

    .content {
      background-color: #000000;
      ul {
        & > li {
          color: #ffffff;
        }
      }
    }
  }
`
