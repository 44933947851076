// noinspection CssUnusedSymbol

import styled from 'styled-components'
import { CommonSignupContent } from '../../../components'

export const LoginContent = styled(CommonSignupContent)`
  .buttons {
    padding-bottom: 1.5rem;
  }

  .inner-link {
    color: black;
    text-align: center;
    margin-bottom: 4rem;

    a {
      color: black;
      font-weight: bold;
    }
  }
`

export const OuterLink = styled.div`
  max-width: 45rem;
  margin: 1.5rem 1rem 2rem;

  @media (min-width: 640px) {
    margin: 1.5rem 0 2rem;
  }

  a {
    color: white;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    white-space: nowrap;
    text-decoration-line: underline;
  }
`
