// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const SelectAccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;

  &.dark {
    .account-info .content .balance {
      color: black;
    }
  }
`

export const SelectAccountLabel = styled.div`
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  margin-left: 2rem;
  margin-bottom: 0.5rem;
`

export const SelectAccountStyled = styled.div`
  width: 100%;
  position: relative;
`

export const AccountButtonStyled = styled.div`
  border: 1px solid #808080;
  border-radius: 4.5rem;
  width: 100%;
  height: 4rem;
  padding-right: 18px;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #afafaf;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &.error {
    border-color: #ff3131;
  }
  &.static {
    border-color: #808080;
    cursor: default;
  }

  .account-info {
    display: flex;
    align-items: center;
    margin-left: 7px;

    .icon {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;

      .balance {
        color: white;
        b {
          font-weight: 700;
        }
      }
    }
  }

  .no-account {
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    margin-left: 2rem;
  }
`

export const AccountListStyled = styled.div<{ opened: boolean }>`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  position: absolute;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: 101;

  background-color: white;
  border-radius: 11px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
  padding: 1rem 0;

  overflow-y: auto;
  max-height: 49.5rem;

  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  .currency-name {
    color: #000 !important;
  }
`

export const AccountStyled = styled.div`
  width: 100%;
  height: 4rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 3rem;

  :hover {
    background: rgba(220, 223, 227, 0.4);
  }

  &.disabled {
    pointer-events: none;
  }

  .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  .icon-all {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: black;
    border-radius: 3.6rem;
    color: white;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;

    .balance {
      color: black;
      b {
        font-weight: 700;
      }
    }
  }
`

export const SelectAccountError = styled.div`
  color: #ff3131;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  margin-top: 5px;
  margin-left: 2.5rem;
`

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 1rem 3rem;

  .button {
    height: 3.4rem;
    width: 100%;
    max-width: 30rem;

    color: #ffffff;
    font-size: 12px;
    line-height: 120%;
  }
`
export const OtherOptionsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  background-color: #85a0ad;

  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`
