import { selectCurrentUserRole, useAppSelector } from 'mmfintech-backend-api'

import { ActivitiesPane } from './ActivitiesPane'
import { OwnerDashboard } from './OwnerDashboard'
import { StaffDashboard } from './StaffDashboard'
import { DashboardWrapper } from './Dashboard.styled'
import { NewInvoiceButton } from '../invoices'

import { CustomerRoleEnum } from 'mmfintech-commons-types'

export const Dashboard = () => {
  const customerRole = useAppSelector(selectCurrentUserRole)

  return (
    <DashboardWrapper>
      {customerRole === CustomerRoleEnum.STAFF ? <StaffDashboard /> : <OwnerDashboard />}
      <ActivitiesPane />
      <NewInvoiceButton />
    </DashboardWrapper>
  )
}
