import styled from 'styled-components'

export const SuggestTransferModalWrapper = styled.div`
  color: white;
  font-size: 1.6rem;
  padding: 4rem 5rem;
  background: #1a1a1a;
  display: grid;

  min-width: 55rem;
  @media (max-width: 565px) {
    max-width: 40rem;
    min-width: 40rem;
    padding: 2rem;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 100%;
  }

  .body {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 100%;
    color: #afafaf;
    margin-top: 2rem;
  }

  .suggest-actions {
    display: grid;
    margin-top: 3rem;
    width: 85%;
    justify-self: center;
    button {
      margin-top: 4rem;
    }
  }
`
